import React from "react";
import PropTypes from "prop-types";
// import FacebookProvider, { Comments as FBComments } from "react-facebook";
import { Disqus } from "gatsby-plugin-disqus";
import config from "../../../content/meta/config";

const Comments = props => {
  const { title, slug, theme } = props;

  const disqusConfig = {
    url: `${config.siteUrl}${slug}`,
    identifier: slug,
    title
  };

  return (
    <React.Fragment>
      <div id="post-comments" className="comments">
        <Disqus config={disqusConfig} />
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        .comments {
          margin: 0 -8px ${theme.space.default};
        }
      `}</style>
    </React.Fragment>
  );
};

Comments.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
};

export default Comments;
